import { Flex, Text, Image, Link } from '@chakra-ui/react';

import logoIAPA from '../../assets/images/logo.png';

export function NotFound(): JSX.Element {
  return (
    <Flex
      w="100%"
      h="100vh"
      justifyContent="center"
      alignItems="center"
      flexDir="column"
    >
      <Link href="https://iapajus.com.br">
        <Image src={logoIAPA} alt="IAPA" />
      </Link>

      <Text fontWeight="bold" fontSize="xl" py={4}>
        Não encontramos o curso que você está procurando.
      </Text>

      <Link href="https://iapajus.com.br">
        Visite o site da IAPA e conheça todos os nossos cursos.
      </Link>
    </Flex>
  );
}
