import { forwardRef, ForwardRefRenderFunction } from 'react';
import {
  FormLabel,
  FormControl,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
} from '@chakra-ui/react';
import { FieldError } from 'react-hook-form';

interface InputProps extends ChakraInputProps {
  name: string;
  label?: string;
  mask?: string;
  maskChar?: string;
  error?: FieldError;
}

export const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> =
  ({ name, label, error, ...rest }, ref) => {
    return (
      <FormControl isInvalid={!!error}>
        {label && (
          <FormLabel
            id={`label-${name}`}
            htmlFor={name}
            textTransform="uppercase"
            color="gray.500"
          >
            {label}
          </FormLabel>
        )}

        <ChakraInput
          id={name}
          name={name}
          bgColor="white"
          variant="filled"
          focusBorderColor="blue.500"
          size="lg"
          _hover={{
            bgColor: 'white',
            borderColor: 'blue.500',
          }}
          _focus={{
            bgColor: 'white',
          }}
          ref={ref}
          {...rest}
        />
      </FormControl>
    );
  };

export const Input = forwardRef(InputBase);
