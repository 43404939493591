import { useEffect, useState } from 'react';
import { Text, HStack } from '@chakra-ui/react';
import { IoHourglassOutline } from 'react-icons/io5';
import { intervalToDuration, add, isWithinInterval, parseISO } from 'date-fns';

type DeadlineProps = {
  product_id: string;
  minutes: number;
  deadline_action: string;
};

export function Deadline({
  product_id,
  minutes,
  deadline_action,
}: DeadlineProps): JSX.Element {
  const [deadlineStart, setDeadlineStart] = useState(() => {
    return localStorage.getItem(`iapa-checkout:dl-${product_id}`);
  });
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    if (!localStorage.getItem(`iapa-checkout:dl-${product_id}`)) {
      setDeadlineStart(new Date().toISOString());
      localStorage.setItem(
        `iapa-checkout:dl-${product_id}`,
        new Date().toISOString(),
      );
    }
  }, [product_id]);

  useEffect(() => {
    let timeInterval: NodeJS.Timeout;

    if (deadlineStart) {
      const startTime = new Date(parseISO(deadlineStart));

      timeInterval = setInterval(() => {
        const interval = intervalToDuration({
          start: new Date(),
          end: add(startTime, { minutes }),
        });

        const timeleft = `${interval.minutes}:${interval.seconds
          ?.toString()
          .padStart(2, '0')}`;

        setTimeLeft(timeleft);

        if (
          !isWithinInterval(new Date(), {
            start: startTime,
            end: add(startTime, { minutes }),
          })
        ) {
          if (timeInterval) {
            clearInterval(timeInterval);
          }

          setTimeLeft('');

          if (!deadline_action) {
            setDeadlineStart(new Date().toISOString());
            localStorage.setItem(
              `iapa-checkout:dl-${product_id}`,
              new Date().toISOString(),
            );
          } else {
            localStorage.removeItem(`iapa-checkout:dl-${product_id}`);

            window.location.href = deadline_action;
          }
        }
      }, 1000);
    }

    return () => {
      if (timeInterval) {
        clearInterval(timeInterval);
      }
    };
  }, [deadlineStart, deadline_action, minutes, product_id]);

  return (
    <HStack bg="red.400" color="white" justifyContent="center" p={4}>
      <Text fontWeight="bold" fontSize="2xl">
        {timeLeft}
      </Text>
      <IoHourglassOutline size={20} />
      <Text fontSize="lg">OFERTA LIMITADA</Text>
    </HStack>
  );
}
