/* eslint-disable react/require-default-props */
import {
  Box,
  VStack,
  Image,
  Heading,
  Text,
  Button,
  HStack,
} from '@chakra-ui/react';

import logoIAPA from '../../assets/images/logo.png';

interface ProductInfo {
  id: number;
  nome_produto: string;
}

type ObrigadoProps = {
  numeroPedido: number;
  product: ProductInfo;
  boletoURL: string | null | undefined;
  boletoBarcode: string | null | undefined;
};
export function Obrigado({
  numeroPedido,
  product,
  boletoURL,
  boletoBarcode,
}: ObrigadoProps): JSX.Element {
  return (
    <Box>
      <VStack
        width="100%"
        my="6"
        maxW={1280}
        height="calc(100vh - 48px)"
        mx="auto"
        px="6"
        position="relative"
      >
        <Box
          backgroundColor="#f5f6ff"
          borderRadius={4}
          p={4}
          w="100%"
          height="100%"
          boxShadow="md"
        >
          <HStack justifyContent="center" paddingBottom={6}>
            <Image src={logoIAPA} alt="IAPA" />
          </HStack>

          <Heading as="h2" fontSize="2xl" py={8}>
            Recebemos o seu pedido. #{numeroPedido}
          </Heading>

          <Text fontWeight="medium" fontSize="2xl" paddingBottom={5}>
            {product.nome_produto}
          </Text>

          {boletoURL && (
            <>
              <Button as="a" href={boletoURL} colorScheme="blue" fontSize="sm">
                <Text>Clique aqui e imprima o seu boleto</Text>
              </Button>
              <Text py={2}>
                ou se preferir pague pela internet usando o código de barras
                abaixo
              </Text>
              <Text fontWeight="medium">{boletoBarcode}</Text>
              <Text py={2}>
                Após a confirmação de pagamento do boleto você receberá em seu
                e-mail o link e instruções de acesso ao curso.
              </Text>
            </>
          )}

          {!boletoURL && (
            <>
              <Text fontSize="xl">
                Confira o seu e-mail com o link e instruções de acesso ao curso.
              </Text>
              <Text>
                Caso não veja o e-mail na sua caixa de entrada, confira se não
                está na caixa de spam ou promoções
              </Text>
            </>
          )}
        </Box>
      </VStack>
    </Box>
  );
}
