export function calculateTaxes(
  capital: number,
  taxaJuros: number,
  quantidadeParcelas: number,
): number[] {
  const installments: number[] = [];
  const jurosPorParcela = taxaJuros / 100;

  Array.from({ length: quantidadeParcelas })
    .fill(0)
    .forEach((_, index) => {
      const parcela = index + 1;
      let valorParceladoComJuros = 0;
      // let valorTotalComJuros = 0;

      if (parcela === 1) {
        valorParceladoComJuros = capital;
        // valorTotalComJuros = capital;
      } else {
        valorParceladoComJuros =
          (capital * jurosPorParcela) / (1 - (1 + jurosPorParcela) ** -parcela);
        // valorTotalComJuros = valorParceladoComJuros * parcela;
      }

      installments.push(Number(valorParceladoComJuros.toFixed(2)));
    });

  return installments;
}
