import { CircularProgress, Flex, Image } from '@chakra-ui/react';

import logoIAPA from '../../assets/images/logo.png';

export function Aguarde(): JSX.Element {
  return (
    <Flex
      w="100%"
      h="100vh"
      justifyContent="center"
      alignItems="center"
      flexDir="column"
    >
      <Image src={logoIAPA} w={177} h={47} alt="IAPA" />
      <CircularProgress isIndeterminate color="red.800" />
    </Flex>
  );
}
