import { forwardRef, ForwardRefRenderFunction } from 'react';
import { useRadio, Box, VStack, RadioProps } from '@chakra-ui/react';

const RadioCardBase: ForwardRefRenderFunction<HTMLInputElement, RadioProps> = (
  props,
  ref,
) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <VStack
      as="label"
      color="gray.300"
      justifyContent="center"
      alignItems="center"
    >
      <input {...input} ref={ref} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        opacity={0.5}
        transition="all 0.2s ease-in-out"
        bg="white"
        _checked={{
          // bg: "blue.50",
          color: 'blue.500',
          borderColor: 'blue.500',
          opacity: 1,
        }}
        p={4}
      >
        {props.children}
      </Box>
    </VStack>
  );
};

export const RadioCard = forwardRef(RadioCardBase);
