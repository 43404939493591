import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { theme } from './styles/theme';
import { Routes } from './routes';

import 'react-toastify/dist/ReactToastify.css';

export function App(): JSX.Element {
  return (
    <ChakraProvider resetCSS theme={theme}>
      <BrowserRouter>
        <Routes />

        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </BrowserRouter>
    </ChakraProvider>
  );
}
