/* eslint-disable no-plusplus */
export function isValidCreditCardNumber(cardNumber: string): boolean {
  const creditCardNumber = cardNumber.split(' ').join('');

  if (
    parseInt(creditCardNumber, 10) <= 0 ||
    !/\d{15,16}(~\W[a-zA-Z])*$/.test(creditCardNumber) ||
    creditCardNumber.length > 16
  ) {
    return false;
  }

  const carray = [];
  for (let i = 0; i < creditCardNumber.length; i++) {
    carray[carray.length] = creditCardNumber.charCodeAt(i) - 48;
  }

  carray.reverse();
  let sum = 0;
  for (let i = 0; i < carray.length; i++) {
    let tmp = carray[i];
    if (i % 2 !== 0) {
      tmp *= 2;
      if (tmp > 9) {
        tmp -= 9;
      }
    }
    sum += tmp;
  }

  return sum % 10 === 0;
}
