export function isValidCPF(cpf: string): boolean {
  if (typeof cpf !== 'string') return false;

  const document = cpf.replace(/[^\d]+/g, '');

  if (document.length !== 11 || !!document.match(/(\d)\1{10}/)) return false;

  const documentSplit = document.split('');

  const validator = documentSplit
    .filter((digit, index, array) => index >= array.length - 2 && digit)
    .map(el => +el);

  const toValidate = (pop: number) =>
    documentSplit
      .filter((digit, index, array) => index < array.length - pop && digit)
      .map(el => +el);

  const rest = (count: number, pop: number) =>
    ((toValidate(pop).reduce((soma, el, i) => soma + el * (count - i), 0) *
      10) %
      11) %
    10;

  return !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1]);
}
