import { Switch, Route } from 'react-router-dom';
import { Checkout } from '../pages/checkout';
import { NotFound } from '../pages/notfound';

export function Routes(): JSX.Element {
  return (
    <Switch>
      <Route path="/:slug" component={Checkout} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
}
