/* eslint-disable react/no-array-index-key */
// https://codesandbox.io/s/51p5y?file=/src/App.tsx:56-72
// https://github.com/sanniassin/react-input-mask/issues/145
// https://pt.stackoverflow.com/questions/385828/como-calcular-valor-de-parcelas-com-jurosgit
import { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Stack,
  VStack,
  HStack,
  SimpleGrid,
  Text,
  Select,
  FormLabel,
  FormControl,
  Heading,
  Divider,
  Collapse,
  useRadioGroup,
  Button,
} from '@chakra-ui/react';
import { AiOutlineSafety } from 'react-icons/ai';
import { FaBarcode, FaCreditCard } from 'react-icons/fa';
import {
  RiPrinterLine,
  RiComputerLine,
  RiCalendarLine,
  RiArrowRightLine,
} from 'react-icons/ri';

import pagarme from 'pagarme';
import Cards from 'react-credit-cards';
import InputMask from 'react-input-mask';

import { useParams, useHistory } from 'react-router-dom';
import { useForm, useWatch, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import { Obrigado } from './obrigado';
import { Aguarde } from './aguarde';
import { NotFound } from '../notfound';
import { Input } from '../../components/forms/input';
import { RadioCard } from '../../components/forms/radio-card';
import { Deadline } from '../../components/deadline';

import { calculateTaxes } from '../../utils/calculate-taxes';
import { isValidCPF } from '../../utils/validate-cpf';
import { isValidCreditCardNumber } from '../../utils/validate-credit-card-number';

import { api } from '../../services/api';

import logoIAPA from '../../assets/images/logo.png';

import 'react-credit-cards/es/styles-compiled.css';

type CheckoutFormData = {
  name: string;
  email: string;
  phone: string;
  document: string;
  street: string;
  street_number: string;
  complementary: string;
  neighborhood: string;
  zipcode: string;
  state: string;
  city: string;
  payment_method: string;
  card_number: string;
  card_holder_name: string;
  card_expiration_date: string;
  card_cvv: string;
  installments: string;
};

const checkoutFormSchema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  email: yup.string().required('E-mail obrigatório').email('E-mail inválido'),
  phone: yup.string().required('Telefone obrigatório'),
  document: yup
    .string()
    .required('CPF obrigatório')
    .test({
      message: 'CPF não é válido',
      test: value => isValidCPF(String(value)),
    }),
  street: yup.string().required('Rua obrigatório'),
  street_number: yup.string().required('Número obrigatório'),
  zipcode: yup
    .string()
    .required('CEP obrigatório')
    .test({
      message: 'CEP obrigatório',
      test: value =>
        String(value)
          .replace(/[^\d]+/g, '')
          .trim() !== '',
    }),
  state: yup.string().required('Estado obrigatório'),
  city: yup.string().required('Cidade obrigatório'),
  payment_method: yup.string(),
  card_number: yup.string().when('payment_method', {
    is: (value: string) => value === 'credit_card',
    then: yup
      .string()
      .required('Número do cartão obrigatório')
      .test({
        message: 'Número do cartão obrigatório',
        test: value =>
          String(value)
            .replace(/[^\d]+/g, '')
            .trim() !== '',
      })
      .test({
        message: 'Número do cartão obrigatório',
        test: value => isValidCreditCardNumber(String(value)),
      }),
  }),
  card_holder_name: yup.string().when('payment_method', {
    is: (value: string) => value === 'credit_card',
    then: yup.string().required('Titular do cartão obrigatório'),
  }),
  card_expiration_date: yup.string().when('payment_method', {
    is: (value: string) => value === 'credit_card',
    then: yup
      .string()
      .required('Validade do cartão obrigatório')
      .test({
        message: 'Validade do cartão obrigatório',
        test: value =>
          String(value)
            .replace(/[^\d]+/g, '')
            .trim() !== '',
      }),
  }),
  card_cvv: yup.string().when('payment_method', {
    is: (value: string) => value === 'credit_card',
    then: yup
      .string()
      .required('CVV do cartão obrigatório')
      .min(3, 'CVV do cartão obrigatório'),
  }),
  installments: yup.string().when('payment_method', {
    is: (value: string) => value === 'credit_card',
    then: yup.string().required('Opções de pagamento obrigatório'),
  }),
});

interface ProductInfo {
  installments: number[];
  banner_side_url: string;
  banner_top_url: string;
  deadline: number;
  deadline_action: string;
  id: number;
  id_oferta: number;
  id_produto: number;
  nome_produto: string;
  recorrente: boolean;
  tipo_recorrencia: string;
  valor: number;
}

type CheckoutURLParams = {
  slug: string;
};

type ConfigCheckout = {
  taxa_juros_cartao: string;
  tipos_pagamento: string;
  total_parcelas_cartao: string;
};

type CheckoutOrder = {
  transaction_id: number;
  status: string;
  boleto_url: string | null;
  boleto_barcode: string | null;
};

export function Checkout(): JSX.Element {
  const history = useHistory();
  const { slug } = useParams<CheckoutURLParams>();

  const [config, setConfig] = useState<ConfigCheckout>({
    taxa_juros_cartao: '0',
    tipos_pagamento: 'boleto',
    total_parcelas_cartao: '1',
  });

  const [product, setProduct] = useState<ProductInfo | null>(null);
  const [notFound, setNotFound] = useState(false);
  const [finished, setFinished] = useState(false);
  const [cardBack, setCardBack] = useState(false);
  const [checkoutOrder, setCheckoutOrder] = useState<CheckoutOrder | null>(
    null,
  );

  const { register, control, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(checkoutFormSchema),
  });

  const { errors } = formState;

  const cvc = useWatch({
    control,
    name: 'card_cvv',
    defaultValue: '',
  });

  const expiry = useWatch({
    control,
    name: 'card_expiration_date',
    defaultValue: '',
  });

  const name = useWatch({
    control,
    name: 'card_holder_name',
    defaultValue: '',
  });

  const number = useWatch({
    control,
    name: 'card_number',
    defaultValue: '',
  });

  const paymentOptions = [
    {
      text: 'Boleto Bancário',
      value: 'boleto',
      icon: FaBarcode,
    },
    {
      text: 'Cartão de Crédito',
      value: 'credit_card',
      icon: FaCreditCard,
    },
  ];

  const {
    getRootProps,
    getRadioProps,
    value: paymentMethodValue,
  } = useRadioGroup({
    name: 'payment_method',
    defaultValue: 'boleto',
    onChange: (value: string) => setValue('payment_method', value),
  });

  const group = getRootProps();

  useEffect(() => {
    const fetchConfig = async () => {
      const { data } = await api.get<ConfigCheckout>('/configs/checkout');

      setConfig(data);
    };

    fetchConfig();
  }, []);

  useEffect(() => {
    if (slug) {
      api.get<ProductInfo>(`/products/${slug}`).then(response => {
        const { data } = response;

        if (response.status === 204) {
          setNotFound(true);
          history.push('/404');
        } else {
          setProduct({
            ...data,
            id_oferta: data.id,
            installments: calculateTaxes(
              data.valor / 100,
              Number(config.taxa_juros_cartao) / 100,
              Number(config.total_parcelas_cartao),
            ),
          });
        }
      });
    }
  }, [config, history, slug]);

  const handleCheckout: SubmitHandler<CheckoutFormData> = async values => {
    try {
      const productData = {
        id: product?.id_produto,
        name: product?.nome_produto,
        id_oferta: product?.id_oferta,
        slug,
      };

      const customer = {
        external_id: new Date().getTime().toString(),
        name: values.name,
        type: 'individual',
        country: 'br',
        email: values.email,
        documents: [
          {
            type: 'cpf',
            number: values.document.replace(/[^\d]+/g, ''),
          },
        ],
        phone_numbers: [`+55${values.phone.replace(/[^\d]+/g, '')}`],
      };

      const customerRecurrence = {
        external_id: '3311',
        name: values.name,
        type: 'individual',
        country: 'br',
        email: values.email,
        address: {
          country: 'br',
          state: values.state,
          city: values.city,
          neighborhood: values.neighborhood,
          street: values.street,
          street_number: values.street_number,
          complementary: values.complementary,
          zipcode: values.zipcode.replace(/[^\d]+/g, ''),
        },
        document_number: values.document.replace(/[^\d]+/g, ''),
        phone: {
          ddd: values.phone.replace(/[^\d]+/g, '').substring(0, 2),
          number: values.phone.replace(/[^\d]+/g, '').substring(2, 11),
        },
      };

      const billing = {
        name: values.name,
        address: {
          country: 'br',
          state: values.state,
          city: values.city,
          neighborhood: values.neighborhood,
          street: values.street,
          street_number: values.street_number,
          complementary: values.complementary,
          zipcode: values.zipcode.replace(/[^\d]+/g, ''),
        },
      };

      const unit_price =
        values.payment_method === 'boleto'
          ? product?.valor
          : Number(
              Number(values.installments) *
                (Number(
                  product?.installments[Number(values.installments) - 1],
                ) *
                  100),
            );

      const items = [
        {
          id: String(product?.id),
          title: product?.nome_produto,
          unit_price,
          quantity: 1,
          tangible: false,
        },
      ];

      const client = await pagarme.client.connect({
        encryption_key: String(process.env.REACT_APP_PAGARME_ENCRYPTION_KEY),
      });

      let card_hash = '';

      if (values.payment_method === 'credit_card') {
        card_hash = await client.security.encrypt({
          card_number: values.card_number,
          card_holder_name: values.card_holder_name,
          card_expiration_date: values.card_expiration_date.replace(
            /[^\d]+/g,
            '',
          ),
          card_cvv: values.card_cvv,
        });
      }

      const { data } = await api.post<CheckoutOrder>('/transactions', {
        async: true,
        capture: true,
        recurrence: product?.recorrente,
        payment_method: values.payment_method ?? 'boleto',
        amount: unit_price,
        installments: values.installments,
        soft_descriptor: product?.nome_produto.substring(0, 13),
        card_hash,
        customer: product?.recorrente ? customerRecurrence : customer,
        billing: product?.recorrente ? null : billing,
        items: product?.recorrente ? null : items,
        product: productData,
      });

      setFinished(true);
      setCheckoutOrder({
        status: data.status,
        boleto_barcode: data.boleto_barcode,
        boleto_url: data.boleto_url,
        transaction_id: data.transaction_id,
      });

      if (['paid', 'waiting_payment'].includes(data.status)) {
        toast.success('Pagamento realizado com sucesso');
      } else {
        toast.error(
          'Pagamento recusado. Entre em contato com a operadora do cartão',
        );
      }
    } catch (error: any) {
      // console.log(error);
      toast.error(
        `Erro ao processar o pagamento, entre em contato com o suporte IAPA. Mensagem: ${
          error.stack || error.message
        }`,
      );
    }
  };

  if (notFound) {
    return <NotFound />;
  }

  if (!product) {
    return <Aguarde />;
  }

  if (finished) {
    return (
      <Obrigado
        numeroPedido={Number(checkoutOrder?.transaction_id)}
        boletoURL={checkoutOrder?.boleto_url}
        boletoBarcode={checkoutOrder?.boleto_barcode}
        product={{
          id: product.id,
          nome_produto: product.nome_produto,
        }}
      />
    );
  }

  return (
    <Box>
      {product.deadline && (
        <Deadline
          product_id={String(product.id)}
          minutes={product.deadline}
          deadline_action={product.deadline_action}
        />
      )}
      <VStack
        width="100%"
        my="6"
        maxW={1280}
        mx="auto"
        px="6"
        position="relative"
      >
        {product.banner_top_url && (
          <img src={product.banner_top_url} alt={product.nome_produto} />
        )}
        <Flex
          width="100%"
          py={6}
          position="relative"
          alignItems="flex-start"
          flexDir={['column-reverse', 'column-reverse', 'row']}
        >
          <Box
            as="form"
            backgroundColor="#f5f6ff"
            borderRadius={4}
            p={4}
            w={['100%', '100%', '70%']}
            boxShadow="md"
            onSubmit={handleSubmit(handleCheckout)}
          >
            <VStack
              justifyContent="flex-start"
              alignItems="flex-start"
              w="100%"
            >
              <HStack paddingBottom={4}>
                <Flex
                  borderRadius="full"
                  backgroundColor="blue.500"
                  color="white"
                  w={8}
                  h={8}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text fontWeight="bold" fontSize="xl">
                    1
                  </Text>
                </Flex>
                <Text fontWeight="bold" fontSize="2xl" color="blue.500">
                  Dados Pessoais
                </Text>
              </HStack>
              <VStack spacing={4} w="100%">
                <Input
                  label="Nome completo"
                  error={errors.name}
                  {...register('name')}
                  autoFocus
                />
                <Input
                  label="E-mail"
                  type="email"
                  error={errors.email}
                  {...register('email')}
                />

                <SimpleGrid minChildWidth="240px" spacing={2} w="100%">
                  <Input
                    label="Telefone"
                    error={errors.phone}
                    as={InputMask}
                    mask="(**) *****-****"
                    {...register('phone')}
                  />
                  <Input
                    label="CPF"
                    error={errors.document}
                    as={InputMask}
                    mask="***.***.***-**"
                    {...register('document')}
                  />
                </SimpleGrid>

                <SimpleGrid minChildWidth="240px" spacing={2} w="100%">
                  <Input
                    label="Rua"
                    error={errors.street}
                    {...register('street')}
                  />
                  <SimpleGrid
                    minChildWidth={['100%', '120px']}
                    spacing={2}
                    w="100%"
                  >
                    <Input
                      label="Número"
                      error={errors.street_number}
                      {...register('street_number')}
                    />
                    <Input label="Complemento" {...register('complementary')} />
                    <Input label="Bairro" {...register('neighborhood')} />
                  </SimpleGrid>
                </SimpleGrid>

                <SimpleGrid minChildWidth="240px" spacing={2} w="100%">
                  <Input
                    label="CEP"
                    error={errors.zipcode}
                    as={InputMask}
                    mask="*****-***"
                    {...register('zipcode')}
                  />
                  <Input
                    label="Estado"
                    maxLength={2}
                    textTransform="uppercase"
                    error={errors.state}
                    {...register('state')}
                  />
                  <Input
                    label="Cidade"
                    error={errors.city}
                    {...register('city')}
                  />
                </SimpleGrid>
              </VStack>
            </VStack>
            <VStack
              justifyContent="flex-start"
              alignItems="flex-start"
              paddingTop={10}
            >
              <HStack paddingBottom={4}>
                <Flex
                  borderRadius="full"
                  backgroundColor="blue.500"
                  color="white"
                  w={8}
                  h={8}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text fontWeight="bold" fontSize="xl">
                    2
                  </Text>
                </Flex>
                <Text fontWeight="bold" fontSize="2xl" color="blue.500">
                  Pagamento
                </Text>
              </HStack>

              <HStack {...group} justifyContent="center" alignItems="center">
                {paymentOptions.map(payment => {
                  if (
                    config.tipos_pagamento &&
                    config.tipos_pagamento.indexOf(payment.value) >= 0
                  ) {
                    const Icon = payment.icon;
                    const radio = getRadioProps({ value: payment.value });
                    return (
                      <RadioCard key={payment.value} {...radio}>
                        <Icon size={32} />
                        {payment.text}
                      </RadioCard>
                    );
                  }
                  return <div />;
                })}
              </HStack>

              <Collapse in={paymentMethodValue === 'boleto'} animateOpacity>
                <VStack py={4} w="100%">
                  <Text
                    textTransform="uppercase"
                    fontWeight="bold"
                    color="gray.500"
                    py={4}
                  >
                    Informações sobre o pagamento via boleto
                  </Text>

                  <Stack spacing={4}>
                    <HStack
                      alignItems="center"
                      justifyContent="flex-start"
                      w="100%"
                    >
                      <Flex
                        w={8}
                        h={8}
                        borderRadius="full"
                        bg="blue.500"
                        alignItems="center"
                        justifyContent="center"
                        color="white"
                      >
                        <RiPrinterLine />
                      </Flex>
                      <Text>
                        Você pode imprimir e{' '}
                        <Text as="span" fontWeight="medium">
                          pagar no banco
                        </Text>
                      </Text>
                    </HStack>

                    <HStack
                      alignItems="center"
                      justifyContent="flex-start"
                      w="100%"
                    >
                      <Flex
                        w={8}
                        h={8}
                        borderRadius="full"
                        bg="blue.500"
                        alignItems="center"
                        justifyContent="center"
                        color="white"
                      >
                        <RiComputerLine />
                      </Flex>
                      <Text>
                        <Text as="span" fontWeight="medium">
                          ou pagar pela internet
                        </Text>{' '}
                        através do código de barras
                      </Text>
                    </HStack>

                    <HStack
                      alignItems="center"
                      justifyContent="flex-start"
                      w="100%"
                    >
                      <Flex
                        w={8}
                        h={8}
                        borderRadius="full"
                        bg="blue.500"
                        alignItems="center"
                        justifyContent="center"
                        color="white"
                      >
                        <RiCalendarLine />
                      </Flex>
                      <Text>
                        Prazo para compensar em até{' '}
                        <Text as="span" fontWeight="medium">
                          3 dias úteis
                        </Text>
                      </Text>
                    </HStack>
                  </Stack>
                </VStack>
              </Collapse>

              <Box w="100%" py={4}>
                <Collapse
                  in={paymentMethodValue === 'credit_card'}
                  animateOpacity
                >
                  <SimpleGrid minChildWidth="240px" spacing={2} w="100%">
                    <Cards
                      cvc={cvc}
                      expiry={expiry}
                      name={name}
                      number={number}
                      focused={cardBack ? 'cvc' : 'number'}
                      placeholders={{ name: 'Seu nome aqui' }}
                      locale={{
                        valid: 'válido até',
                      }}
                    />
                    <VStack spacing={4}>
                      <Input
                        label="Número do Cartão"
                        as={InputMask}
                        mask="**** **** **** ****"
                        error={errors.card_number}
                        {...register('card_number')}
                      />
                      <Input
                        label="Titular do Cartão"
                        error={errors.card_holder_name}
                        {...register('card_holder_name')}
                      />
                      <SimpleGrid minChildWidth="120px" spacing={2} w="100%">
                        <Input
                          label="Validade"
                          as={InputMask}
                          mask="**/**"
                          error={errors.card_expiration_date}
                          {...register('card_expiration_date')}
                        />
                        <Input
                          label="CVV"
                          maxLength={3}
                          error={errors.card_cvv}
                          onFocus={() => {
                            setCardBack(true);
                          }}
                          onBlurCapture={() => {
                            setCardBack(false);
                          }}
                          {...register('card_cvv')}
                        />
                      </SimpleGrid>

                      <FormControl>
                        <FormLabel
                          id="label-installments"
                          htmlFor="installments"
                          textTransform="uppercase"
                          color="gray.500"
                        >
                          Opções de Pagamento
                        </FormLabel>
                        <Select
                          id="installments"
                          bgColor="white"
                          variant="filled"
                          focusBorderColor="blue.500"
                          size="lg"
                          _hover={{
                            bgColor: 'white',
                            borderColor: 'blue.500',
                          }}
                          _focus={{
                            bgColor: 'white',
                          }}
                          error={errors.installments}
                          {...register('installments')}
                        >
                          {product.installments.map((installment, index) => (
                            <option value={index + 1} key={index}>
                              {`${index + 1}x ${Intl.NumberFormat('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(installment)}${index > 0 ? '*' : ''}`}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </VStack>
                  </SimpleGrid>
                </Collapse>
              </Box>
            </VStack>
            <Flex
              as="footer"
              justifyContent={['center', 'flex-end']}
              width="100%"
              flexDir="row"
              py={4}
            >
              <Button
                p={8}
                colorScheme="green"
                color="white"
                fontSize="lg"
                textTransform="uppercase"
                type="submit"
                isLoading={formState.isSubmitting}
              >
                Finalizar Pagamento
                <RiArrowRightLine size={24} />
              </Button>
            </Flex>
          </Box>
          <Box
            position={['relative', 'sticky']}
            top={[0, 5]}
            w={['100%', '100%', '30%']}
            marginInline="0 !important"
            py={[0, 5]}
            marginBottom={[4, 0]}
            borderRightRadius={4}
            zIndex={-1}
          >
            <VStack
              justifyContent="flex-start"
              alignItems="flex-start"
              bg="white"
            >
              <Flex
                bg="green.500"
                width="100%"
                justifyContent="center"
                alignItems="center"
                p={4}
                borderTopRightRadius={4}
              >
                <AiOutlineSafety size={20} color="white" />
                <Text
                  textTransform="uppercase"
                  color="white"
                  fontWeight="bold"
                  fontSize="sm"
                  paddingLeft={2}
                >
                  Compra 100% segura
                </Text>
              </Flex>
              <VStack
                p={5}
                width="100%"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Heading
                  as="h2"
                  size="md"
                  color="blue.600"
                  fontWeight="bold"
                  textTransform="uppercase"
                >
                  {product.nome_produto}
                </Heading>
                <Divider py={5} />
                <Text fontSize="xl" color="blue.600" fontWeight="bold">
                  {product.installments.length}x{' '}
                  {Intl.NumberFormat('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(
                    product.installments[product.installments.length - 1],
                  )}
                  *
                </Text>
                <Text fontSize="sm">
                  ou{' '}
                  {Intl.NumberFormat('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(product.valor / 100)}
                </Text>

                {product.installments.length >= 6 && (
                  <>
                    <Text fontSize="xl" color="blue.600" fontWeight="bold">
                      3x{' '}
                      {Intl.NumberFormat('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(product.installments[2])}
                      *
                    </Text>
                    <Text fontSize="sm">
                      ou{' '}
                      {Intl.NumberFormat('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(product.valor / 100)}
                    </Text>
                  </>
                )}

                {product.banner_side_url && (
                  <HStack w="100%" justifyContent="center" paddingTop={5}>
                    <img
                      src={product.banner_side_url}
                      alt={product.nome_produto}
                    />
                  </HStack>
                )}
              </VStack>
              <VStack
                bg="gray.100"
                width="100%"
                color="gray.600"
                p={5}
                justifyContent="flex-start"
                alignItems="flex-start"
                borderBottomRightRadius={4}
              >
                <Text fontSize="xs">
                  *Parcelamento com tarifa de{' '}
                  {(Number(config.taxa_juros_cartao) / 100).toFixed(2)}% a.m
                </Text>
                <Text fontSize="xs">
                  Ao prosseguir você está concordando com os{' '}
                  <Text as="a" href="https://iapajus.com.br">
                    Termos de compra
                  </Text>
                </Text>
              </VStack>
            </VStack>
          </Box>
        </Flex>
        <a href="https://iapajus.com.br">
          <img src={logoIAPA} alt="IAPA" />
        </a>
      </VStack>
    </Box>
  );
}
